import container from '@di'
import { useUserStore, useAppStore } from '@/stores'
import { useLocalStream } from '@/composables'

export default class MuteUnmuteProcedure {
  constructor () {
    this.name = 'muteUnmuteProcedure'
    this.user = useUserStore()
    this.app = useAppStore()
    this.subscription = null

    this.eventHandlersMap = new Map([
      ['MUTE_CAMERA', () => { 
        this.mute('video')
      }],
      ['UNMUTE_CAMERA', (state) => {
        if (state.event.reinitialize) {
          this.reinitialize('camera')
        } else {
          this.unmute('video')
        }
      }],
      ['MUTE_MICROPHONE', (state) => {
        this.mute('audio', { isSilent: state.event.isSilent })
      }],
      ['UNMUTE_MICROPHONE', () => {
        this.unmute('audio')
      }]
    ])
  }

  install = () => {
    
    this.app.service.subscribe((state) => {
      if (this.eventHandlersMap.has(state.event.type)) {
        this.eventHandlersMap.get(state.event.type)(state)
      }
    })
  }
  
  uninstall = () => {
    this.subscription.unsubscribe()
  }

  mute (type, options = { isSilent: false}) {
    this.user.mute(type, options.isSilent)
  }

  unmute (type) {
    this.user.unmute(type)
  }

  reinitialize (device) {
    const { addTrack, addAndroidTrack, stream: localStream } = useLocalStream()
    
    localStream.video.stop()
    if (container.androidConnector.connected && localStream.video.source === 'android') {
      addAndroidTrack(device)
        .then(() => {
          this.app.transition(`${device}_available`.toUpperCase())
          this.user.profile[device] = 'on'
        })
        .catch((error) => {
          console.error(error)
          app.transition(`${device}_not_available`.toUpperCase())
        })
    } else {
      addTrack(device)
      .then(() => {
        this.app.transition(`${device}_available`.toUpperCase())
        this.user.profile[device] = 'on'
      })
      .catch((error) => {
        console.error(error)
        app.transition(`${device}_not_available`.toUpperCase())
      })
    }

  }
}
