import container from '@di'
import { mute, unmute, devicesShortNamesMap } from '@browser'
import LocalVideoStream from './LocalVideoStream' 
import LocalAudioStream from './LocalAudioStream'
import Constraints from './Constraints'
export default class LocalStream {
  constructor (initialConstraints, idealConstraints) {
    this.messenger = container.messenger
    this.constraints = new Constraints(initialConstraints, idealConstraints)
    this.video = new LocalVideoStream()
    this.audio = new LocalAudioStream()
  }

  addTrack = (type, options = {}, isRecursiveCall = false) => {
    return new Promise(async (resolve, reject) => {
      try {
        const shortType = devicesShortNamesMap.get(type)
        const stream = await navigator.mediaDevices.getUserMedia({ [shortType]: this.constraints.constraints[shortType] })
        const track = stream.getTracks()[0]

        track.enabled = !!!options.muted?.[shortType]
        track.addEventListener("ended", () => {
          container.messenger.emit('stream:track:end', { name: `${track.kind}_capture`, status: 'denied' })
        })
        this[shortType].addTrack(track, 'browser')
        container.messenger.emit('stream:track:start', { name: `${track.kind}_capture`, status: 'granted' })
        resolve({
          track
        })
      } catch (error) {
        if (error instanceof OverconstrainedError && !isRecursiveCall) {
          const shortType = devicesShortNamesMap.get(type)
          if (this.constraints.constraints[shortType] !== undefined) {
            delete this.constraints.constraints[type]
            this.constraints.delete(type)
            this.constraints.init()
            setTimeout(() => {
              this.addTrack(type, options, true)
                .then((track) => resolve(track))
                .catch((error) => reject(error))
            }, 5000)
          }
        } else {
          reject(error)
        }
      }
    })
  }

  addAndroidTrack = async (type) => {
    const shortType = devicesShortNamesMap.get(type)
    const track = await container.androidConnector.getTrack(shortType)
    this[shortType].addTrack(track, 'android')
  }

  getTrack = async (type, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        type = devicesShortNamesMap.get(type)
        const stream = await navigator.mediaDevices.getUserMedia({ [type]: this.constraints.constraints[type] })
        const track = stream.getTracks()[0]
        track.enabled = !!!options.muted?.[type]
        resolve(stream.getTracks()[0])
      } catch (error) {
        reject(error)
      }
    })
  }

  isAndroidDevice = (deviceId) => {
    if (container?.androidConnector?.state?.devices && Array.isArray(container.androidConnector.devices)) {
      return !!container.androidConnector.devices.find(device => device.deviceId === deviceId)
    }
    return false
  }

  changeDevice = async (device, deviceId, label, options) => {
    this.constraints.update(device, deviceId, label)
    const type = devicesShortNamesMap.get(device)
    this[type].stop()
    if (this.isAndroidDevice(deviceId)) {
      await this.addAndroidTrack(device)  
    } else {
      await this.addTrack(device, options)
    }
  }

  replaceDevice = async (device, deviceId, label, options) => {
    this.constraints.update(device, deviceId, label)
    const type = devicesShortNamesMap.get(device)
    const oldTrack = this.getTrackByType(type)
    oldTrack.stop()
    let newTrack = null
    if (this.isAndroidDevice(deviceId)) {
      const shortType = devicesShortNamesMap.get(device)
      newTrack = await container.androidConnector.getTrack(shortType)
    } else {
      newTrack = await this.getTrack(device, options)
    }
    // const newTrack = await this.getTrack(device, options)
    this[type].removeTrack(oldTrack)
    this[type].addTrack(newTrack)
    container.messenger.emit(`stream:replaceTrack:${type}`, { oldTrack, newTrack, stream: this[type].stream.value })
    this.updateAt = Date.now()
  }
  
  getTrackByType = (type) => {
    return this[type].getTrack()
  }

  mute = (trackType) => {
    if (trackType === 'audio') mute(this.audio.stream.value, trackType)
    if (trackType === 'video') mute(this.video.stream.value, trackType)
  }

  unmute = (trackType) => {
    if (trackType === 'audio') unmute(this.audio.stream.value, trackType)
    if (trackType === 'video') unmute(this.video.stream.value, trackType)
  }

  hasTorchCapability = async () => {
    const track = this.getTrackByType('video')
    if (track) {
      if ('ImageCapture' in window) {
        const capture = new window.ImageCapture(track)
        const capabilities =  await capture.getPhotoCapabilities()
        return capabilities?.fillLightMode?.length > 0
      }
    }
  }

  async toggleTorch () {
    const track = this.getTrackByType('video')
    await track.applyConstraints({
      advanced: [{
        torch: !track.getSettings().torch
      }]
    })
    return track.getSettings().torch
  }

  stop = () => {
    this.video.stop()
    this.audio.stop()
  }
}
